import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './numberswhy-styles.scss'

const NumbersWhy = ({ number, descr }) => {
  const { t, i18n } = useTranslation()
  const num = number.split('+')
  return (
    <div className="numberswhy__container">
      <p className='numberswhy__number'>
        {num[0]}
        {num.length > 1 && <sup className="numberswhy__plus">+</sup>}
      </p>
      <div className='numberswhy__descr' dangerouslySetInnerHTML={{ __html: descr }} />
    </div>
  )
}

export default NumbersWhy
