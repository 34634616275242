import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import ButtonCircle from '../../../../../components/button-circle'
import './servicesCard-styles.scss'
import { useTranslation } from 'react-i18next'

const ServicesCard = ({
  imageUrl,
  imageAlt,
  title,
  text,
  imageSecondUrl,
  imageSecondAlt,
  oppositeCard,
  slug,
}) => {
	const { i18n } = useTranslation();
  const serviceClasses = classNames({
    servicesCard__container: true,
    oppositeCard,
  })

  return (
    <div className={serviceClasses}>
      <div className='servicesCard__firstImage'>

        <img src={imageUrl} alt={imageAlt} />
      </div>
      <div className='servicesCard__information'>
        <div className="servicesCard__text-container">
          <h3>{title}</h3>
          <div dangerouslySetInnerHTML={{ __html: text }} />
          <ButtonCircle link={`${i18n.language === 'en' ? '/' : `/${i18n.language}/`}services/${slug}`} />
        </div>
        <div className='servicesCard__bottom'>
          <div className='servicesCard__secondImage'>
            <img src={imageSecondUrl} alt={imageSecondAlt} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesCard
