import React, { useEffect, useState } from 'react'
import makeRequest from '../../helpers/make-request'
import HomeView from './home-view'
import { useTranslation } from 'react-i18next'

const HomeController = () => {
  const [loading, setLoading] = useState(true)
  const [dataHome, setDataHome] = useState([])
  const controller = new AbortController()

  const { i18n } = useTranslation();

  const getDataHome = async () => {
    console.log('Home 1');
    setLoading(true)
    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'pages', // place the endpoint of where the data is in, from the json file
      signal: controller.signal,
      params: {
        lang: i18n.language,
        slug: 'home',
      },
    }).then((resp) => {
      setDataHome(resp.data[0].acf.rows) // why not possible to subtract the above from this one?
      setLoading(false)
    })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }
  useEffect(() => {
    getDataHome()
    return () => {
      controller.abort()
    }
  }, [])

  const viewProps = {
    data: dataHome,
    loading,
  }
  return (
    <HomeView {...viewProps} />
  )
}

export default HomeController
